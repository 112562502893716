var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "zui-footer" }, [
    _c("div", { staticClass: "footer-content-box" }, [
      _c("table", { staticClass: "form-table", attrs: { cellspacing: "0" } }, [
        _c("thead", [
          _c("tr", {}, [
            _c("th", [_vm._v(_vm._s(_vm.$t("footer.purchase")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("footer.communities")))]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c(
              "td",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.mail")))]
            ),
            _c(
              "td",
              {
                on: {
                  click: function ($event) {
                    return _vm.toGo("gzh")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.wechat")))]
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                on: {
                  click: function ($event) {
                    return _vm.toGo("taobao")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.taobao")))]
            ),
            _c(
              "td",
              {
                on: {
                  click: function ($event) {
                    return _vm.toGo("xhs")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.little_read_book")))]
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                on: {
                  click: function ($event) {
                    return _vm.toGo("ebay")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.eBay")))]
            ),
            _c(
              "td",
              {
                on: {
                  click: function ($event) {
                    return _vm.toGo("douyin")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.douyin")))]
            ),
          ]),
        ]),
      ]),
      _c("table", { staticClass: "form-table", attrs: { cellspacing: "0" } }, [
        _c("thead", [
          _c("tr", {}, [
            _c("th", [_vm._v(_vm._s(_vm.$t("footer.support_center")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("footer.business")))]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c(
              "td",
              {
                on: {
                  click: function ($event) {
                    return _vm.toGo("AC")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.ac_center")))]
            ),
            _c(
              "td",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "InvestorsPage" })
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.relations")))]
            ),
          ]),
          _c("tr", [
            _c("td"),
            _c(
              "td",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "InvestorsFormPage" })
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.joining")))]
            ),
          ]),
          _c("tr", [
            _c("td", [_vm._v(" ")]),
            _c(
              "td",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "InvestorsFormPage" })
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.global_dealer")))]
            ),
          ]),
        ]),
      ]),
      _c("p", { staticClass: "borders" }),
      _c("div", { staticClass: "links" }, [
        _c("div", [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "clausePage" })
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("footer.clause")))]
          ),
        ]),
        _c("div", [
          _c("span", { staticClass: "zui-right-world" }),
          _c("span", { staticClass: "zui-right-language-text" }, [
            _vm._v(_vm._s(_vm.$t("language.lang_all"))),
          ]),
        ]),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "copy-right" }, [
      _vm._v(" 2022-2027 © ACHENCYBE 版权所有.         "),
      _c(
        "a",
        {
          staticStyle: { color: "#646464" },
          attrs: { href: "http://beian.miit.gov.cn/" },
        },
        [_vm._v("京ICP备2022009322号")]
      ),
      _vm._v(
        "     本站直接或间接为消费者提供商品或服务的商业宣传均属于“广告”（包装及参数、售后保障等商品信息除外） "
      ),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }