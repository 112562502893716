<template>
  <footer class="zui-footer">
    <div class="footer-content-box">
      <table class="form-table" cellspacing="0">
        <thead>
        <tr style="">
          <th>{{ $t('footer.purchase') }}</th>
          <th>{{ $t('footer.communities') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td @click="$router.push('/')">{{ $t('footer.mail') }}</td>
          <td @click="toGo('gzh')">{{ $t('footer.wechat') }}</td>
        </tr>
        <tr>
          <td @click="toGo('taobao')">{{ $t('footer.taobao') }}</td>
          <td @click="toGo('xhs')">{{ $t('footer.little_read_book') }}</td>
        </tr>
        <tr>
          <td @click="toGo('ebay')">{{ $t('footer.eBay') }}</td>
          <td @click="toGo('douyin')">{{ $t('footer.douyin') }}</td>
        </tr>
        </tbody>
      </table>
      <table class="form-table" cellspacing="0">
        <thead>
        <tr style="">
          <th>{{ $t('footer.support_center') }}</th>
          <th>{{ $t('footer.business') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td @click="toGo('AC')">{{ $t('footer.ac_center') }}</td>
          <td @click="$router.push({name:'InvestorsPage'})">{{ $t('footer.relations') }}</td>
        </tr>
        <tr>
          <td></td>
          <td @click="$router.push({name:'InvestorsFormPage'})">{{ $t('footer.joining') }}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td @click="$router.push({name:'InvestorsFormPage'})">{{ $t('footer.global_dealer') }}</td>
        </tr>
        </tbody>
      </table>
      <p class="borders" />

      <div class="links">
        <div>
          <a @click="$router.push({name:'clausePage'})">{{ $t('footer.clause') }}</a>
        </div>
        <div>
          <span class="zui-right-world"></span>
          <span class="zui-right-language-text">{{$t('language.lang_all')}}</span>
        </div>
      </div>
      <p class="copy-right">
        2022-2027 © ACHENCYBE 版权所有.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="http://beian.miit.gov.cn/" style="color:#646464">京ICP备2022009322号</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本站直接或间接为消费者提供商品或服务的商业宣传均属于“广告”（包装及参数、售后保障等商品信息除外）
        <br>

      </p>
    </div>


  </footer>
</template>
<script>
import "./less/footer.less";

export default {
  methods: {
    toGo(type) {
      this.$router.push({ name: "FooterDetail", query: { type: type } });
    }
  }
};
</script>